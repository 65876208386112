/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react'
import http from 'service/http'
// import socket from 'service/socket'
import numeral from 'numeral'
import moment from 'moment'
import store from 'store'
import config from 'config'
import Bottom from 'component/bottom'
import Header from 'component/header'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-final-form-hooks'
import { Button, Form } from 'antd';
import Joi from '@hapi/joi'
import formCreateInputs from 'service/form/create'
import validate from 'service/form/validation'

import useDialog from '../dialog/hook'
import useLoading from '../loading/hook'
import './style.css'
import InputField from '../../component/textInput/InputField'
import { navigate } from '@reach/router'
import MenuItem from '../../component/layout/MenuItem'
import BoxLeft from '../../component/layout/BoxLeft'

// socket.on('login', (data) => {
//   localStorage.clear();
//   alert('Your account just login from other place !')
//   window.location = 'login'
// })

function Account({ history }) {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(false)
  const [gameBalances, updateGameBalances] = useState([])
  const [user, updateUser] = useState({})
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Your Account'
  })
    const handlePushLinks = link => {
      history.push(link)
    }

  const fetchAccount = async () => {
    const { user_id: userId } = store.get('user')
    const [userResp, gameBalancesResp] = await withLoading(() => Promise.all([
      http.get({ path: 'me' }),
      http.get({ path: `users/${userId}/balance-games` })
    ]))
    updateGameBalances(gameBalancesResp.filter(i => i).filter(({ game_code: gameCode }) => config.gamesAvailable.includes(gameCode)))
    store.set('user', userResp)
    updateUser(userResp)
    let bl = userResp.user_money;
    userResp.balanceGame.filter(x => x.balance.game_code !== 'asiagaming').forEach(r => {
      bl += Number(r.balance.balance)
      // console.log(r, bl)
    })
    store.set('userBalance', bl)
    store.set('userBalanceTime', Date.now())
  }

  const onPullOut = async () => {
    const { user_id: userId } = store.get('user')
    const tasks = []
    gameBalances.forEach(({ gameId, balance }) => {
      if (balance > 1) {
        tasks.push(http.post({
          path: `users/${userId}/game/${gameId}/withdraw`, payload: {
            amount: balance
          }
        }))
      }
    })
    await withLoading(() => Promise.all(tasks))
    showDialogWithMessage('Pull out done!')
    fetchAccount()
  }

  useEffect(() => {
    // socket.on('messages', () => {
    //   fetchAccount()
    // })
    fetchAccount()
  }, [])

  const [ErrorDialog, showDialogErrorWithMessage] = useDialog({
    title: 'Change Password Error',
    btnLabel: 'Got it',
    type: 'error'
  })

  const [SuccessDialog, showDialogSuccessWithMessage] = useDialog({
    title: 'Change Password Success',
  })

  const onSubmit = async ({ password }) => {
    try {
      const { user_id: userId } = store.get('user')
      await withLoading(() =>
        http.put({ path: `users/${userId}`, payload: { password } })
      )
      showDialogSuccessWithMessage('Change password success!')
      navigate('/account')
      window.location.reload();
    } catch (error) {
      showDialogErrorWithMessage(error.message)
    }
  }
  const handleSubmit = (values) => {
    onSubmit({ ...values })
  }
  const schema = Joi.object().keys({
    // oldPassword: Joi.string().regex(/^[a-zA-Z0-9]{3,30}$/).required(),
    password: Joi.string().regex(/^[a-zA-Z0-9]{3,30}$/).required(),
    confirmPassword: Joi.string().valid(Joi.ref('password')).required(),
  })

  // const { form, handleSubmit, submitting } = useForm({
  //   onSubmit,
  //   validate: validate(schema)
  // })

  // const [password, confirmPassword] = formCreateInputs(['password', 'confirmPassword'], form)

  const {
    username,
    fullname,
    user_money: balance,
    mobile_phone: phone,
    email,
    bankName,
    bankAccountNumber,
    created_at: createdAt,
    updated_at: updatedAt,
    user_currency: currency,
  } = user
  const [form] = Form.useForm();
  return (
    <div className="containerActive">
      <MenuItem />
      <div className="styled-title-router">My Profile</div>
      <div className="styledContainer">
        <BoxLeft />
        <div className="profile-container">
          <div className="containerBox">
            <p className="title-profile">Base Profile</p>
            <div className="deposit-left-main profile-main">
              <div className="profile-item display_flex">
                <p className="text-profile">{t('BASE_PROFILE')}</p>
                <span>{username}</span>
              </div>
              <div className="profile-item display_flex">
                <p className="text-profile">{t('REFERAL_ID')}</p>
                <span>{username}</span>
              </div>
              <div className="profile-item display_flex">
                <p className="text-profile">{t('EMAIL_ADDRESS')}</p>
                <span>{email}</span>
              </div>
              <div className="profile-item display_flex">
                <p className="text-profile">{t('PHONE')}</p>
                <span>{phone}</span>
              </div>
              <div className="profile-item display_flex">
                <p className="text-profile">{t('MAIN_BALANCE')}</p>
                <span>{currency}</span>
              </div>
              <div className="profile-item display_flex">
                <p className="text-profile">{t('BANK')}</p>
                <span>{bankName}</span>
              </div>
              <div className="profile-item display_flex">
                <p className="text-profile">{t('BANK_NUMBER')}</p>
                <span>{bankAccountNumber}</span>
              </div>
              {/* <div className="profile-item display_flex">
              <p className="text-profile">{t('BANK_ACCOUNT_NAME')}</p>
              <span>PT Bank</span>
            </div> */}
              <div className="profile-item display_flex">
                <p className="text-profile">{t('JOIN_DATE')}</p>
                <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
              </div>
              <div className="profile-item display_flex">
                <p className="text-profile">{t('LAST_EDIT')}</p>
                <span>{moment(updatedAt).format('DD/MM/YYYY')}</span>
              </div>
            </div>
          </div>
          <div className="containerBox">
            <p className="title-profile">Change Password</p>
            <div className="deposit-right-main profile-main">
              <div>
                <Form
                  form={form}
                  layout="vertical"
                  className="form-deposit changepass"
                  onFinish={handleSubmit}
                >
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!'
                      }
                    ]}
                    name="password"
                    className="custom-label-account"
                    label=" Current Password"
                    style={{ marginBottom: '10px' }}
                  >
                    <InputField
                      type="password"
                      size="default"
                      placeholder="password"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!'
                      }
                    ]}
                    name="password"
                    className="custom-label-account"
                    label="New Password"
                    style={{ marginBottom: '10px' }}
                  >
                    <InputField
                      type="password"
                      size="default"
                      placeholder="password"
                    />
                  </Form.Item>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!'
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve()
                          }
                          return Promise.reject(
                            new Error(
                              'The two passwords that you entered do not match!'
                            )
                          )
                        }
                      })
                    ]}
                    name="confirmPassword"
                    className="custom-label-account"
                    label="Confirm password"
                  >
                    <InputField
                      type="password"
                      size="default"
                      placeholder="Confirm password.."
                    />
                  </Form.Item>

                  <div className="text-center formGroup_btn">
                    <Button
                      style={{ width: '100%' }}
                      type="primary"
                      loading={loading}
                      htmlType="submit"
                    >
                      {t('SAVE')}
                    </Button>
                  </div>
                </Form>
                <ErrorDialog />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorDialog />
      <SuccessDialog />
    </div>
  )
}

export default Account
