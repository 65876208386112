import React, { useState, useEffect } from 'react'
import numeral from 'numeral'
import StyledButton from '../../button/syledButton'
import './boxstyle.css'
import config from 'config'
import store from 'store'
import http from 'service/http'
// import socket from 'service/socket'
import { useTranslation } from 'react-i18next'
import useLoading from '../../../feature/loading/hook'
import useDialog from '../../../feature/dialog/hook'

const BoxLeft = ({ history }) => {
  const { t } = useTranslation()
  const [loading, withLoading] = useLoading(true)
  const [gameBalances, updateGameBalances] = useState([])
  const [user2, updateUser] = useState({})
  const user = store.get('user') || {};
  const [Dialog, showDialogWithMessage] = useDialog({
    title: 'Your Account'
  })

  const fetchAccount = async () => {
    const { user_id: userId } = store.get('user')
    const [userResp, gameBalancesResp] = await withLoading(() => Promise.all([
      http.get({ path: 'me' }),
      http.get({ path: `users/${userId}/balance-games` })
    ]))
    updateGameBalances(gameBalancesResp.filter(i => i).filter(({ game_code: gameCode }) => config.gamesAvailable.includes(gameCode)))
    store.set('user', userResp)
    updateUser(userResp)
    let bl = userResp.user_money;
    userResp.balanceGame.filter(x => x.balance.game_code !== 'asiagaming').forEach(r => {
      bl += Number(r.balance.balance)
      // console.log(r, bl)
    })
    store.set('userBalance', bl)
    store.set('userBalanceTime', Date.now())
  }

  // const fetchAccount = async () => {
  //   const { user_id: userId } = store.get('user')
  //     const [userResp] = await withLoading(() =>
  //       Promise.all([http.get({ path: 'me' })])
  //     )

  //     store.set('user', userResp)
  //     updateUser(userResp)
  //     // console.log(userResp)
  //     // console.log(userResp.balanceGame)
  //     updateGameBalances(
  //       userResp.balanceGame
  //         .filter(x => x.balance)
  //         .map(t => {
  //           return t.balance
  //         })
  //     )
  // }

  useEffect(() => {
    // socket.on('messages', () => {
    //   fetchAccount()
    // })
    fetchAccount()
  }, [])

  const onPullOut = async () => {
    // console.log("aaaaa")
    const { user_id: userId } = store.get('user')
    const tasks = []
    gameBalances.forEach(({ gameId, balance, game_code }) => {
      if (balance > 1 && game_code !== 'asiagaming') {
        const providerCode = game_code === 'ag_galaxy' ? '?ProviderId=AG' : ''
        tasks.push(
          http.post({
            path: `users/${userId}/game/${gameId}/withdraw${providerCode}`,
            payload: {
              amount: balance
            }
          })
        )
      }
    })
    await withLoading(() => Promise.all(tasks))
    showDialogWithMessage('Pull out done!')
    fetchAccount()
  }

  const { user_money: balance } = user

  return (
    <div className="boxleft-wrapper">
      <div className="boxleft-style-user">
        <img
          style={{ width: '44px' }}
          src="./images/header/use.png"
          alt="use"
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
          }}
        >
          <span
            style={{
              fontSize: '14px',
              lineHeight: '18px',
              color: '#000',
              fontWeight: '600'
            }}
          >
            Wellcome
          </span>
          <span
            style={{
              fontSize: '14px',
              lineHeight: '18px',
              color: '#0071E3',
              fontWeight: '500'
            }}
          >
            {user.user_account_no}
          </span>
        </div>
      </div>
      <div className="boxleft-balance">
        <div className="boxleft-item">
          <div style={{ height: '40px' }}></div>
          <div className="boxleft-linewhite" />
          <p
            className="boxleft-text"
            style={{ fontSize: '16px', color: '#000' }}
          >
            0/50000
          </p>
        </div>
        <div className="boxright-item">
          {loading ? (
            <div
              style={{ width: '100%', textAlign: 'center' }}
              className="pro_title"
            >
              {t('LOADING')} ...
            </div>
          ) : (
            <>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <p className="boxleft-title">Wallet Balance</p>
                <img
                  style={{ width: '20px', cursor: 'pointer' }}
                  src="./images/home/reload.png"
                  alt=""
                  onClick={() => fetchAccount()}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  color: '0071E3',
                  padding: '5px 0'
                }}
              >
                <p className="boxleft-text">{user.user_currency}</p>
                <p className="boxleft-text">
                  {numeral(balance).format('0,0.00')}
                </p>
              </div>
              <StyledButton onClick={() => onPullOut()} background="#0071E3">
                Restore
              </StyledButton>
            </>
          )}
        </div>
      </div>
      <div className="boxleft-userveri">
        <div className="boxleft-item">
          <p className="boxleft-title">User Verification</p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
              padding: '10px 0'
            }}
          >
            <div className="boxleft-styleviri">
              <img
                style={{ width: '30px', height: '30px' }}
                src="./images/home/user.svg"
                alt=""
              />
              <div className="boxleft-line" />
            </div>
            <div className="boxleft-styleviri">
              <img
                style={{ width: '30px', height: '30px' }}
                src="./images/home/mail.svg"
                alt=""
              />
              <div className="boxleft-line" />
            </div>
            <div className="boxleft-styleviri">
              <img
                style={{ width: '30px', height: '30px' }}
                src="./images/home/phone.svg"
                alt=""
              />
              <div className="boxleft-line" />
            </div>
            <div className="boxleft-styleviri">
              <img
                style={{ width: '30px', height: '30px' }}
                src="./images/home/page.svg"
                alt=""
              />
              <div className="boxleft-line" />
            </div>
          </div>
        </div>
        {/* <div className="boxleft-item">
          <img style={{ height: '200px' }} src="./images/home/qr.svg" alt="" />
        </div> */}
      </div>
    </div>
  )
}

export default BoxLeft
