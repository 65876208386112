export default [
  {
    src: './images/home/casinovip.png',
    classshow: '',
    code: 'VipCasino',
    label: 'VipCasino',
    route: '/casino',
    idrActive: 0,
    id: 33
  },
  {
    src: './images/home/casinowebet.png',
    classshow: 'pages-icon-hot',
    // src: '/images/gameLogo/world-intertainment.png',
    code: 'WE',
    label: 'WE',
    route: 'we',
    idrActive: 0,
    id: 26
  },

  {
    src: './images/home/casinosexy.png',
    classshow: '',
    // src: '/images/gameLogo/casino-sexy.png',
    code: 'sexy_baccarat',
    label: 'Sexy Baccarat',
    route: 'awc/SEXYBCRT/LIVE',
    idrActive: 0,
    // game: ,
    id: 23
  },
  {
    src: './images/home/casinopp.png',
    classshow: '',
    // src: '/images/gameLogo/pp-live-casino.png',
    code: 'sexy_baccarat',
    label: 'PP',
    route: 'awc/PP/LIVE',
    idrActive: 0,
    id: 23
  },
  {
    src: './images/home/casinobiggaming.png',
    classshow: '',
    // src: '/images/gameLogo/casino-biggaming.png',
    code: 'BGAWS',
    label: 'Big gaming',
    route: 'big-gaming',
    idrActive: 0,
    id: 28
  },
  {
    src: './images/home/casinovenus.png',
    classshow: '',
    // src: '/images/gameLogo/casino-venus.png',
    code: 'sexy_baccarat',
    label: 'Venus Casino',
    route: 'venus',
    idrActive: 0,
    id: 23
  }
  // {
  //   src: 'images/icon/AGicon.png',
  //   code: 'asiagaming',
  //   label: 'Asia Gaming',
  //   route: '/asiagaming?GameType=LC',
  //   id: 21
  // }, {
  //   src: 'images/icon/evoicon.png',
  //   code: 'evolution',
  //   label: 'EVOLUTION',
  //   route: '/evolution-games',
  //   id: 15
  // }, {
  //   src: 'images/icon/playtechicon.png',
  //   code: 'playtech',
  //   label: 'Playtech',
  //   route: '/authenticate/playtech/7bal',
  //   idrActive: 1,
  //   id: 4,
  // },
  // {
  //   src: '/img/mega.png',
  //   code: 'mega',
  //   label: 'Mega888',
  //   route: '/',
  //   id: 11,
  //   deeplink: true
  // },
]
